.div-form-profile {
    position: relative;
    width: 400px;
}

.div-form-profile label {
    position: relative;
    left: -50px;
    width: 140px !important;
}

.div-form-profile input {
    position: relative;
    left: -40px;
    width: 300px;
}

.uploadComponent {
    display: none !important;
}

.passwordStrength {
    left: -40px;
}