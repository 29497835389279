.parent {
    justify-content: center;
    display: flexbox;
}

.container {
    text-align: center;
    width: 80%;
}

.containerSteps {
    margin: 0 auto 5rem;
    width: 60%;
}

.containerPeriodo {
    display: flex;
}

.input {
    width: 250px !important;
    margin-bottom: 20px !important;
    border: 1px solid #d9d9d9;
  
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum", "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    height: 32px;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 16pt !important;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px; 
}

.button {
    margin-top: 5rem;
}

.labelCardBeneficiario {
    font-size: 2rem;
}

.labelPeriodo {
    width: 8rem;
    height: fit-content;
    margin-top: 4px;
    font-weight: 600;
}

.modalEstorno p {
    font-weight: bold;
}

.modalEstorno a {
    font-weight: normal;
}

.textEstornoPontos {
    font-size: 0.9rem;
    font-weight: 600;
    display: flex;
}